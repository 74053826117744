.table {
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            background: #DFEBFF;
        }

        td {
            padding: 6px 12px;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
            @include tablet() {
                font-size: 13px;
            }
            @include tablet-wide() {
                padding: 8px 16px;
                font-size: 16px;
            }
            @media screen and (min-width:1400px){
                padding: 12px 24px;
                font-size: 16px;
                
            }
            &:first-child {
                border-radius: 6px 0 0px 0px;
                @include laptop() {
                    border-radius: 16px  0 0 0;
                }
            }

            &:last-child {
                border-radius: 0 6px 0px 0;
                @include laptop() {
                    border-radius: 0 16px  0 0;
                }
            }
        }

    }

    tbody {
        tr {
            transition: .2s;

            td {
                padding: 8px 12px;
                font-size: 12px;
                border: 1px solid #F3F7FF;
                @include tablet() {
                    font-size: 13px;
                }
                @include tablet-wide() {
                    padding: 8px 16px;
                    font-size: 16px;
                }
                @media screen and (min-width:1400px){
                    padding: 12px 24px;
                    font-size: 16px;
                    
                }

                b {
                    font-weight: 600;
                }
            }
            &.selected,
            &:hover {
                background-color: #F3F7FF;
            }

        }

    }
}
.table-responsive{
    width: 100%;
    overflow-x: auto;
    max-width: 100%;
}

table.bt thead,
table.bt tbody th {
    display: none;
}

table.bt tfoot th,
table.bt tfoot td,
table.bt tbody td {
    border: none;
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    vertical-align: top;
    /* IE 9 */
    float: left\9;
    width: 100%\9;
}

table.bt tfoot th::before,
table.bt tfoot td::before,
table.bt tbody td::before {
    content: attr(data-th) ": ";
    display: inline-block;
    -webkit-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    font-weight: bold;
    width: 6.5em;
}

table.bt tfoot th.bt-hide,
table.bt tfoot td.bt-hide,
table.bt tbody td.bt-hide {
    display: none;
}

table.bt tfoot th .bt-content,
table.bt tfoot td .bt-content,
table.bt tbody td .bt-content {
    vertical-align: top;
}

.bt-wrapper.active {
    max-height: 310px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

table.bt.bt--no-header tfoot td::before,
table.bt.bt--no-header tbody td::before {
    display: none;
}