.modal {
    width: 100%;
    max-width: 600px;
    display: none;
    &_small {
        max-width: 340px;
    }
    &_medium{
        max-width: 460px;
    }
    &__title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 20px;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    &__choise {
        padding: 10px;
        &:not(:last-child){
            border-bottom: 1px solid #f5f5f5;
        }
        &:hover{
            background-color: $color_blue;
            color: $color_white;
        }
    }
    &__buttons-row{
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
        &_col{
            flex-direction: column;
            align-items: center;
        }
    }
    &__buttons-or{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 10px 0;
    }
}