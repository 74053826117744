.form {
    &__text {
        display: block;
        width: 100%;
        height: 34px;
        border: none;
        border-bottom: 1px solid rgba($color: $color_black, $alpha: .4);
        outline: none;
        font-size: 16px;
        font-family: $font_main;
        border-radius: 0;
        &:focus{
            background-color: rgba($color: $color_black, $alpha: .05);
        }
        &_df{
            display: flex;
            align-items: center;
            min-height: 34px;
            height: auto;
        }

    }
}