.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px solid $color_black;
    border-radius: 0;
    gap: 16px;
    color: $color_white;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $color_black;
    cursor: pointer;
    transition: .2s;
    &_block{
        width: 100%;
    }
    @include tablet(){
        font-size: 20px;
        padding: 19px 26px 19px 32px;
    }
    &:hover{
        background: $color_yellow;
        color: $color_black;
        border-color: $color_yellow;
        svg{
            transform: translate(10px,-10px);
            fill: $color_black;
        }
    }
    &_white{
        border-color: $color_white;
        background-color: transparent;
    }
    svg{
        width: 18px;
        height: 18px;
        fill: $color_white;
        transition: .2s;
        @include tablet(){
            width: 24px;
            height: 24px;
        }
    }
    &_clear{
        background-color: $color_white;
        border: 1px solid $color_black;
        color: $color_black;
    }
    &_radius{
        border-radius: 8px;
    }
    &_opacity{
        opacity: 0.5;
        &:hover{
            opacity: 1;
        }
    }
}