.checkbox{
    display: inline-block;
    position: relative;
    line-height: 1.5;
    cursor: pointer;
    &_empty{
        span{
            padding-left: 18px!important;
        }
    }
    input{
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        &:checked{
            & ~ span{
                &:after{
                    opacity: 1;
                }
            }
        }
    }
    span{
        padding-left: 26px;
        &:before{
            content: '';
            width: 18px;
            height: 18px;
            border: 2px solid $color_deepdark;
            position: absolute;
            top: 3px;
            left: 0;
            box-sizing: border-box;
            border-radius: 3px;
        }
        &:after{
            content: '';
            box-sizing: border-box;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 3px;
            left: 0;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%230146B7' d='M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2ZM7.71 13.29a.995.995 0 0 1-1.41 0L2.71 9.7a.997.997 0 0 1 1.41-1.41L7 11.17l6.88-6.88a.997.997 0 0 1 1.41 1.41l-7.58 7.59Z'/%3E%3C/svg%3E") no-repeat;
            border-radius: 3px;
            overflow: hidden;
            transition: .2s;
            opacity: 0;
        }
    }
}