.form {
    display: block;
    width: 100%;
    &__item {
        flex: 1;
    }
    &__row{
        display: flex;
        gap: 24px;
        flex-direction: column;
        @include tablet{
            flex-direction: row;
        }
    }
    &__devider{
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.05);
        margin: 6px 0;
        &_24{
            margin: 24px 0;
        }
    }
    &__lbl {
        font-size: 14px;
        color: rgba($color: $color_black, $alpha: .8);
        margin-top: 2px;
        &_df{
            display: flex;
            gap: 10px;
        }
    }
}