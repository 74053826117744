.form__select{
    display: block;
    width: 100%;
    height: 34px;
    border: none;
    border-bottom: 1px solid rgba($color: $color_black, $alpha: .4);
    outline: none;
    font-size: 16px;
    font-family: $font_main;
    padding: 0;
    border-radius: 0;

}