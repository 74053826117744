.statistic {
    &__row {
        min-width: 0;
        @include tablet() {
            display: flex;
            gap: 24px;
        }
    }
    &__table {
        flex: 1 1 100%;
        background: #FFFFFF;
        border-radius: 0px 0px 16px 16px;
        filter: drop-shadow(0px 0px 30px rgba(0, 10, 27, 0.05));

    }
    &__stats {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 24px;
        @include tablet() {
            margin-top: 0;
            flex: 0 0 300px;
        }
        @include laptop() {
            flex: 0 0 368px;
            gap: 24px;
        }
    }
    &__stat {
        background: #000A1B;
        box-shadow: 0px 0px 30px rgba(0, 10, 27, 0.05);
        border-radius: 6px;
        flex: 0 0 calc(50% - 6px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $color_white;
        padding: 10px;
        min-height: 80px;
        font-size: 14px;
        text-align: center;
        @include laptop() {
            flex: 0 0 calc(50% - 12px);
            border-radius: 16px;
            font-size: 16px;
        }
        &.full{
            flex: 0 0 100%;
        }
        &.blue{
            background-color: $color_blue;
        }
        span{
            display: block;
            font-size: 20px;
            font-weight: 600;
            margin-top: 6px;
        }
    }
    &__charts {
        margin-top: 24px;
        @include tablet-wide() {
            display: flex;
            gap: 24px;
        }
    }
    &__tabs {
        flex: 1 1 100%;
        border-radius: 16px;
        filter: drop-shadow(0px 0px 30px rgba(0, 10, 27, 0.05));
        background: #FFFFFF;
        min-width: 0;
        @include tablet() {
            padding: 12px;
        }
        @media screen and (min-width: 1300px){
            display: flex;
            gap: 14px;
            padding: 24px;
        }
    }
    &__tabs-list{
        display: flex;
        gap: 10px;
        min-width: 200px;
        flex-wrap: wrap;
        padding: 12px;
        @include tablet(){
            padding: 0;
            margin-bottom: 24px;
        }
        @media screen and (min-width: 1300px){
            flex-direction: column;
            gap: 8px;
            margin-bottom: 0;
        }
    }
    &__tabs-item{
        box-shadow: 0px 0px 30px rgba(0, 10, 27, 0.05);
        border-radius: 6px;
        padding: 8px 8px;
        cursor: pointer;
        transition: .2s;
        font-size: 12px;
        @include tablet() {
            font-size: 14px;
            border-radius: 12px;
            padding: 13px 10px;
        }
        &:hover,
        &.active{
            background-color: $color_blue;
            color: $color_white;
        }

    }
    &__tabs-chart{
        flex: 1 1 100%;
        min-width: 0;
        width: 100%;
        margin-top: 24px;
        @include tablet() {
            margin-top: 0;
            width: auto;
        }
        #myChart{
            height: 100%;
        }
    }
    &__circles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 24px;
        background: #FFFFFF;
        border-radius: 16px;
        filter: drop-shadow(0px 0px 30px rgba(0, 10, 27, 0.05));
        gap: 24px;
        margin-top: 24px;
        @include tablet() {
        }
        @include tablet-wide() {
            margin-top: 0;
            flex: 0 0 300px;
        }
        @include laptop() {
            flex: 0 0 368px;
            gap: 24px;
        }
        .mkCharts{
            flex: 0 0 calc(50% - 12px);
            text-align: center;
            max-width: 120px;
            svg{
                width: 100%;
                height: 100%;
            }
            @include tablet() {
                // order: initial;
            }
            @include laptop() {
                flex: 0 0 calc(50% - 12px);
            }
        }
        &-lbl{
            text-align: center;
            flex: 0 0 100%;
            height: 0;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            transform: translateY(-0.7em);
            order: -1;
            margin-top: 10px;
            @include tablet-wide() {
                order: initial;
                margin-top: 0;
            }

        }
    }
}
