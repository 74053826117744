.menu-btn {
    display: inline-block;
    padding: 10px 0;
    position: relative;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    margin-left: auto;
    @include tablet() {
        background-color: rgba($color: $color_white, $alpha: .05);
    }
    @media screen and (min-width:1300px){
        display: none;   
    }

    &__wrap {
        width: 20px;
        height: 16px;
        position: relative;

        span {
            width: 100%;
            height: 2px;
            background-color: $color_white;
            top: 50%;
            margin-top: -1px;
            position: absolute;
            transition: opacity .2s, transform .2s;

            &:nth-child(1) {
                transform: translateY(-7px);
            }

            &:nth-child(3) {
                transform: translateY(7px);
                width: 60%;
            }
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: translateY(0) rotate(45deg);
            }

            &:nth-child(3) {
                transform: translateY(0) rotate(-45deg);
                width: 100%;
            }

            &:nth-child(2) {
                transform: translateY(0) translateX(-80px);
                opacity: 0;
            }
        }
    }
}