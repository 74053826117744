.lead {
    @include tablet(){
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }
    @include tablet-wide(){
        flex-wrap: nowrap;
    }
    &__col {
        flex: 1;
        .lead__group{
            &:not(:last-child){
                margin-bottom: 26px;
            }
        }
        &_tablet-full{            
            @include tablet(){
                flex: 0 0 100%;
            }
            @include tablet-wide(){
                flex: 1;
            }
        }
    }
    &__group{
        background: #FFFFFF;
        border-radius: 8px;
        padding: 24px 16px;
        filter: drop-shadow(0px 0px 30px rgba(0, 10, 27, 0.05));
        &_nobg{
            background: none;
            filter: none;
            padding: 0;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        &_df{
            display: flex;
            align-items: center;
            gap: 24px;
            svg{
                width: 32px;
                height: 32px;
                transition: .2s;
            }
            a:hover{
                svg{
                    fill: $color_blue;
                }
            }
        }
    }
    &__fields {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 10px;
    }
    &__controls{
        display: flex;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 12px;
        @include tablet(){
            margin-top: 0;
        }
    }
    &__timeline{
        // width: 100%;
        width: auto;
        font-size: 14px;
        line-height: 1.2;
        td{
            vertical-align: top;
            padding: 4px 4px 4px 0;
            &:nth-child(1){
                color: #313131;
            }
            &:nth-child(2){
                color: #7C7C7C;
            }
        }
    }
}