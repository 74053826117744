.sidebar {
    width: 46px;
    background-color: $color_deepdark;
    color: $color_white;
    position: relative;
    text-align: center;
    padding: 5px;
    transition: .2s;
    &.active{
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        padding: 5px 24px;
        z-index: 99;
        .sidebar{
            &__logo{
                visibility: visible;
            }
            &__user{
                visibility: visible;
            }
            &__balance{
                visibility: visible;
            }
            &__sticky{
                width: 252px;
            }
        }
    }
    @media screen and (min-width:1300px){
        position: static;
        width: 300px;
        flex: 0 0 300px;
        padding: 24px;
        
    }
    &__sticky {
        position: sticky;
        top: 24px;
        height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        @media screen and (min-width:1300px){
            visibility: visible;            
        }
    }
    &__logo {
        visibility: hidden;
        width: 100px;
        display: inline-block;
        margin-left: 10px;
        margin-top: -30px;
        @include tablet(){
        }
        @media screen and (min-width:1300px){
            visibility: visible;    
            width: auto;  
            margin: 0 auto;      
        }
    }
    &__user {
        margin-top: 20px;
        line-height: 1.5;
        margin-bottom: 16px;
        visibility: hidden;
        display: none;
        @include tablet-wide(){
            margin-top: 50px;
            display: block;
        }
        @media screen and (min-width:1300px){
            visibility: visible;            
        }
        span{
            font-weight: 600;
            font-size: 20px;
        }
    }
    &__balance {
        background: #F3F7FF;
        border-radius: 12px;
        color: $color_black;
        padding: 16px;
        text-align: center;
        visibility: hidden;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        margin-top: 10px;
        @include tablet(){
            display: block; 
            white-space: nowrap;           
        }
        @media screen and (min-width:1300px){
            visibility: visible;            
        }
    }
    &__balance-lbl {
        display: none;
        @include tablet(){
            display: block;
        }
    }
    &__balance-count {
        font-weight: 600;
        font-size: 16px;
        margin-top: 4px;
        @include tablet(){
            font-size: 20px;
        }
    }
    &__balance-control {
        font-size: 14px;
        a{
            @media screen and (max-width:767px){
                border: none;            
            }
        }
        @include tablet(){
            margin-top: 10px;
        }
        
    }
    &__nav {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 20px;
        margin-bottom: 15px;
        @include tablet(){
            margin-top: 40px;
        }
    }
    &__nav-item {
    }
    &__nav-link {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 6px 5px;
        color: $color_deeplight;
        font-size: 16px;
        border-radius: 8px;
        white-space: nowrap;
        @include tablet(){
            font-size: 18px;
        }
        @media screen and (min-width:1300px){
            padding: 12px 24px;          
        }
        svg{
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            fill: currentColor;
        }
        span{

        }
        &.active,
        &:hover{
            background: #438AFE;
        }
    }
    &__bottom{
        margin-top: auto;
        font-size: 14px;
        @include tablet(){
            font-size: 18px;
        }
        a{
            color: $color_deeplight;
        }
    }
}
.overlay{
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.6;
    display: none;
}