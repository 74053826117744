.pagination {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    @include tablet(){
        font-size: 16px;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}