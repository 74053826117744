.form__textarea{
    display: block;
    width: 100%;
    min-height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    outline: none;
    resize: none;
    font-size: 16px;
    padding: 15px 10px;
}