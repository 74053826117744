.login {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        picture,
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__wrap {
        width: 100%;
        background-color: #fff;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 30px;
        min-height: 400px;
        @media screen and (min-width: 400px) {
            width: 400px;
            padding: 30px 50px;
            
        }
        @include tablet(){
            width: 400px;
            padding: 30px 50px;
        }
    }
    &__logo {
    }
    &__form {
    }
    &__form-row {
        margin-top: 15px;
    }
    &__form-password {
        position: relative;
    }
    &__form-eye {
        width: 30px;
        height: 30px;
        position: absolute;
        right: -3px;
        top: 2px;
        padding: 3px;
        cursor: pointer;
        transition: .2s;
        &:hover{
            svg{
                fill: $color_blue;
            }
        }
        svg{
            width: 24px;
            height: 24px;
            fill: #000;
            transition: .2s;
        }
    }
    &__form-btn{
        margin-top: 30px;
    }
    &__form-forgot{
        font-size: 14px;
        text-align: right;
    }
}

