.header {
    margin: -24px -24px 30px;
    &_blue{
        background-color: $color_blue;
        color: $color_white;
    }
    &_dark{
        background-color: $color_black;
        color: $color_white;
    }
    &__row {
        display: flex;
        align-items: center;
        min-height: 64px;
        padding: 7px 24px;
        @include tablet(){
            min-height: 84px;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        gap: 16px;

        @include tablet(){
            font-size: 24px;
        }
        svg{
            width: 24px;
            height: 24px;
            fill: $color_white;
        }
    }
    &__title-back{
        padding-top: 4px;
        padding-left: 10px;
        margin-left: -10px;
        background-color: rgba($color: $color_white, $alpha: .09);
        &:hover{
            background-color: $color_blue;
        }
    }
    &__title-inform{
        display: block;
        color: #FD6262;
        font-size: 18px;
    }
}
